import PropTypes from 'prop-types';
import React from 'react';

import {
  container,
  title as titleClass,
  description as descriptionClass,
} from 'components/HeroText/HeroText.module.scss';

import { altHeader } from 'styles/components/typography.module.scss';

const HeroText = ({
  title,
  subtitle = null,
  description = null,
  containerClass = null,
  containerStyles = null,
}) => {
  return (
    <div
      style={containerStyles}
      className={`${container}${containerClass ? ' ' + containerClass : ''}`}
    >
      <h1 className={titleClass}>{title}</h1>
      {subtitle && <h2 className={altHeader}>{subtitle}</h2>}
      {description && <div className={descriptionClass}>{description}</div>}
    </div>
  );
};

HeroText.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.node,
  description: PropTypes.node,
  containerClass: PropTypes.object,
  containerStyles: PropTypes.object,
};

export default HeroText;
