import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';

import Container from 'components/Container/Container';
import HeroText from 'components/HeroText/HeroText';
import Layout from 'components/Layout';
import Seo from 'components/Seo';

import { btnLink } from 'styles/components/links.module.scss';
// import { banner } from 'styles/pages/common.module.scss';
import {
  contentContainer,
  content,
  img,
} from 'styles/pages/etaireia.module.scss';

const CompanyPage = () => {
  return (
    <Layout fullWidth>
      <Seo title="Εταιρεία" />
      <HeroText title="Εταιρεία" subtitle="ΑΥΤΗ ΕΙΝΑΙ Η ΙΣΤΟΡΙΑ ΜΑΣ" />
      <Container withPadding>
        <div className={contentContainer}>
          <div className={content}>
            <h2>ΦΑΙΔΡΑ (*) ΦΩΤΕΙΝΗ, ΛΑΜΠΟΥΣΑ ΑΠΟ ΧΑΡΗ! </h2>
            <p>Είκοσι χρόνια τώρα στην οδό Ερμού, στην καρδιά της πόλης…</p>
            <p>
              Με «μποέμ» διάθεση, κέφι, υψηλή αισθητική, χρώματα και υλικά,
              υπηρετούμε την ομορφιά ακολουθώντας τις βασικές αρχές της.
              Διαλέγουμε πρωτοτυπία, επιλέγουμε φυσικές ίνες, μαλλί, μετάξι και
              βαμβάκι, αγκαλιάζοντας ανάγκες, ικανοποιώντας τις ευγενείς
              αισθήσεις των φίλων και πελατών μας. Χειροτεχνούμε τη διακόσμηση
              των χώρων που στεγάζουν χαρές, με ζεστασιά και τρυφερότητα…
            </p>
            <p>
              Ντύνουμε κάθε σύγχρονη γυναίκα με «μόδα» που είναι αποκλειστικά
              σχεδιασμένη γι’αυτήν, που δεν επιβάλλεται αλλά διαλέγεται….
            </p>
            <p>
              Υπηρετούμε τις γυναίκες των οποίων η ζωή δεν καθοδηγείται από τις
              τάσεις αλλά και από τη διάθεση. Γυναίκες φυσικές, που ζουν την
              κάθε στιγμή και που αντιδρούν στο κόσμο που τους περιβάλλει. Με
              ρούχο, κόσμημα, διακόσμηση χώρων…
            </p>
            <p>
              (*) Το όνομα «Φαίδρα» προέρχεται από το επίθετο φαιδρός (που
              σημαίνει φωτεινός) είναι η φωτεινή, η λάμπουσα από χάρη…
            </p>
            <Link className={btnLink} to="/epikoinonia">
              ΕΠΙΚΟΙΝΩΝΗΣΤΕ ΜΑΖΙ ΜΑΣ
            </Link>
          </div>
          <StaticImage
            className={img}
            src="../images/company.jpg"
            alt="Faidra Concept"
          />
        </div>
      </Container>
    </Layout>
  );
};

export default CompanyPage;
